import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './Home.css'
import { Link } from 'react-router-dom';
const Intro = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="introsec">
        <Container>
          <Row className='py-5'>
            <Col>
              <div className='introsec-firstrow'>
                {data.map((row, index) => (
                  <>
                    <h1>{row.headingone}</h1>
                    <p>{row.buttonone}</p>
                  </>
                ))}
                <Link to={'/contact'}>
                  <Button>CONTACT FINTECH LAW</Button></Link>
              </div>
            </Col>

          </Row>
        </Container>
      </section>

    </>
  )
}

export default Intro