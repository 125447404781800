import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import "./blog.css";
import "../Home/Home.css";

const PAGE_SIZE = 6;

const Secondsec = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-categories`);
      const result = await response.json();
      setCategories(result);
    };

    fetchCategories();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const categoryQuery = selectedCategory ? `&category=${selectedCategory}` : "";
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/get-posts?page=${pageNum}${categoryQuery}`
      );
      const result = await response.json();

      // Update data with the new posts
      setData((prev) => [...prev, ...result]);

      // Check if we need more data
      if (result.length < PAGE_SIZE) {
        setHasMoreData(false); // Stop loading more if we don't have more items
      }

      setPageNum(pageNum + 1);
    } catch (err) {
      setHasMoreData(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setData([]); // Reset data for new category
    setPageNum(1); // Reset page number to 1 for new category
    setHasMoreData(true); // Reset infinite scroll to load more
  };

  const styles = {
    width: "100%",
  };

  const renderLoader = () => (
    <div key={data.length}>
      <div
        className="spinner-border"
        role="status"
        style={{ color: "white", display: "block", margin: "auto" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <>
      <section className="blog-page-second-sec">
        <Container>
          <Row>
            <Col md={12}>
              <Row>
                <Link className="mx-auto text-center mb-5" to={"/contact"}>
                  <Button>CONTACT FINTECH LAW!</Button>
                </Link>
              </Row>
              <Dropdown className="mb-4">
                <Dropdown.Toggle variant="" id="dropdown-basic" className="custom-dropdown-toggle">
                  {selectedCategory || "ALL POSTS"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu text-lg">
                  <Dropdown.Item onClick={() => handleCategoryChange("")} className="custom-dropdown-item">
                    ALL POSTS
                  </Dropdown.Item>
                  {categories.map((category, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleCategoryChange(category)}
                      className="custom-dropdown-item"
                    >
                      {category}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div style={{ height: "100%" }}>
                {(isLoading || !data.length) && renderLoader()}
                <InfiniteScroll
                  pageStart={0}
                  dataLength={data.length}
                  loadMore={fetchData}
                  initialLoad={true}
                  hasMore={hasMoreData && !isLoading}
                  loader={renderLoader()}
                  threshold={450}
                >
                  {data?.length > 0 ? (
                    <Row>
                      {data.map((row, index) => (
                        <Col md={4} key={index} className="blog1stcol">
                          <Link to={"/blog/" + row.slug} className="blog-link">
                            <Card className="blog-box" style={{ minHeight: "300px", maxWidth: "100%" }}>
                              <Card.Img
                                variant="top"
                                style={{
                                  objectFit: "cover", height: "180px", width: "100%"
                                }}
                                src={
                                  "https://storage.googleapis.com/fintechlegal/posts/" +
                                  row.document
                                }
                              />
                              <Card.Body className="blog-body">
                                <Card.Title className="blog-title" style={{ fontSize: "18px" }}>
                                  {row.posttitle}
                                </Card.Title>
                                <Row style={styles}>
                                  <Col className="blog-tag-col">
                                    { /* {row.tags.split(",").map((tag, tagIndex) => (
                                      <div key={tagIndex}>
                                        <Button
                                          variant="outline-light"
                                          className="blogtag-btn"
                                        >
                                          {tag.trim()}
                                        </Button>
                                      </div>
                                    ))}*/ }
                                  </Col>
                                  {row.selectedTeam && (
                                    <p style={{ fontSize: "15px" }}>
                                      {row.selectedTeam.membername}
                                      {/*new Date(row.date).toLocaleDateString()*/}
                                    </p>
                                  )}
                                </Row>
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <div></div>
                  )}
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
