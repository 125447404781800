import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Secimg from '../../assets/home-about-lg-graphic.webp'
const Secondsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const styles = {
    width: '100%'
  }
  return (
    <section className='second-sec'>
      <Container>
        <Row>
          <Col md={6}>
            {data.map((row, index) => (

              <>
                <h1>{row.headingtwo}</h1>

                <p>{row.subheadingtwo}
                </p>
              </>
            ))}
            <Link to={'/about'}>
              <Button style={{ marginBottom: '30px' }}>ABOUT US</Button>
            </Link>

          </Col>
          <Col md={6}>
            <img src={Secimg} style={styles} /></Col>
        </Row>
      </Container>
    </section>
  )
}

export default Secondsec