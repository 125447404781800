import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const Secondsec = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-contactpage`
    );
    const result = await response.json();
    setData(result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="second-sec-contact">
        <Container>
          <Row>
            <Col className="contact-first-col">
              <h2>Schedule A Consultation?</h2>

              {data.map((row, index) => (
                <>
                  <p>{ReactHtmlParser(row.desc)}</p>
                </>
              ))}
              <a
                className="nounderline"
                href="https://calendly.com/fintechlaw/30min"
                target="_blank"
              >
                <Button>BOOK A MEETING</Button>
              </a>
            </Col>
            <Col className="contact-second-col">
              <h2 className="pl-4">Contact Us</h2>

              <iframe
                src="https://forms.zohopublic.com/codyfoster/form/ContactUsFinTechLawFirm/formperma/c3q_oAOtgbrHdKNK91Ljl5pGd2RWjxBKtdAN6fGAvr4?zf_rszfm=1"
                width="100%"
                height="750px"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Secondsec;
