import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import blog from '../../assets/resources-blog-image.webp'
import blog2 from '../../assets/resources-events-image.webp'


const Secondsec = () => {
    const [data, setData] = useState([]);




    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-additionalresources`);
        const result = await response.json();
        setData(result);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
    };
    useEffect(() => {
        fetchpartnerpage();
    }, []);
    return (
        <>
            <section className='resources-sec-1'>
                <Container>
                    {partner.map((row, index) => (

                        <p>{row.ctatitleheading}
                        </p>
                    ))}
                    <Row>
                        <Col md={2}>
                        </Col>
                        <Col>
                            <Card >
                                <Card.Img variant="top" src={blog} />

                            </Card>
                            <Link to={'/blog'}>
                                <Button variant="primary">FINTECH LAW BLOG</Button></Link>

                        </Col>
                        <Col>
                            <Card >
                                <Card.Img variant="top" src={blog2} />
                                {/* <Card.Body className='res-card'>
               
            </Card.Body> */}
                            </Card>
                            <Link to={'/events'}>
                                <Button variant="primary">EVENTS</Button></Link>

                        </Col>
                        <Col md={2}>
                        </Col>

                    </Row>
                </Container>
            </section>


            <section className='fourth-sec'>
                <Container>
                    <Row>
                        <Col md={8}>
                            <p>We are experts in private and registered funds, SEC, FINRA<br /> regulations, and everything in between. Connect with us today.
                            </p>
                        </Col>
                        <Col><Button>CONTACT FINTECH LAW</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    )
}

export default Secondsec


{/* <section className='resources-second-sec'>
<Container fluid>
    <Row className='py-5'>
        <Col>
            <h1>Additional Resources & Downloads</h1>
        </Col>
    </Row>
    <Row  className='py-5'>
    {data.map((row, index) => (

        <Col md={4}>
            <Card  >
                <Card.Header className='pink-card'> <span className='serv'>{row.additionalresourcestitle}</span></Card.Header>
                <Card.Body>
                {/* <Card.Title className='c-t'>Get Compliance Expertise</Card.Title> 
                <Card.Text className='c-p'>
                {ReactHtmlParser(row.additionalresourcesdesc)}
                </Card.Text>
                <Link to={row.additionalresourcesbutton}>
                <Button variant="primary">DOWNLOAD GUIDE</Button></Link>

                </Card.Body>
            </Card>
        </Col>
    ))}
        {/* <Col>
            <Card >
                <Card.Header className='first-card'><span className='serv'>Regulating Crypto <br/>eBook</span></Card.Header>
                <Card.Body>
                <Card.Text className='c-p'>
                This guide considers the major issues around regulating cryptocurrency as they apply to RIA firms and their clients.                            </Card.Text>
                <Button variant="primary">DOWNLOAD GUIDE</Button>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card >
                <Card.Header className='light-card'><span className='serv'>AI & Regtech <br/>eBook</span></Card.Header>
                <Card.Body>
                <Card.Text className='c-p'>
                This guide on artificial intelligence and regtech was developed to supplement the session titled “Will AI Revolutionize the Financial Services Industry?” at the 2021 NSCP National Conference.
                </Card.Text>
                <Button variant="primary">DOWNLOAD GUIDE</Button>

                </Card.Body>
            </Card>
        </Col> 
        
    </Row>
    {/* <Row>
        <Col>
            
        </Col>
        <Col>
            <Card >
                <Card.Header className='pink-card'><span className='serv'>CCO's Guide to Surviving a <br/>Regulatory Examination</span></Card.Header>
                <Card.Body>
                <Card.Text className='c-p'>
                Every firm will go through an examination at some point. In this guide, we summarize the collective experience of our CCO consultants to help you make it through your next exam as cleanly as possible.
                </Card.Text>
                <Button variant="primary">DOWNLOAD GUIDE</Button>

                </Card.Body>
            </Card>
        </Col>
        <Col>
            
        </Col>
        
    </Row> 
</Container>
</section> */}