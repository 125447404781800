import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./blogdetail.css";

const Secondsec = () => {
  const [eventstitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState("");

  const params = useParams();

  const styles = {
    width: "100%",
  };

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-events/${params.id}`
    );
    result = await result.json();
    setName(result.eventstitle);
    setDesc(result.description);
    setExcerpt(result.excerpt);
    setFileName(result.document);

    setDate(
      new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()
    ); // Retrieve date from _id field
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  return (
    <section className="blog-detail-secod-sec">
      <Container>
        <img
          style={styles}
          src={"https://storage.googleapis.com/fintechlegal/posts/" + fileName}
        />

        <Row>
          <Col className="">
            {/* <h1>{posttitle}</h1> */}
            <p className="blog-date">{date}</p>
            <p className="blog-inner-detail-desc">
              {ReactHtmlParser(description)}
            </p>

            <Row className="py-5 article-share-icons">
              <h2 className="py-3">Share this Event</h2>
              <Col>
                <FontAwesomeIcon icon={faFacebook} size="3x" className="px-2" />
                <FontAwesomeIcon icon={faLinkedin} size="3x" className="px-2" />
                <FontAwesomeIcon icon={faTwitter} size="3x" className="px-2" />
                <br />
                <Link to={"/events"} className="nounderline">
                  <Button>VIEW MORE EVENTS</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Secondsec;

