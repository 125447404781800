import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Fourthsec = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-registeredfunds`);
        const result = await response.json();
        setpartner(result);
    };
    useEffect(() => {
        fetchpartnerpage();
    }, []);
    return (
        <section className='fourth-sec'>
            <Container>
                <Row>
                    <Col>
                        <iframe src='https://forms.zohopublic.com/codyfoster/form/ComplianceUpdatesFTL/formperma/ra3WF4tQLb1XkKAxk3fkkEgt5A2zN4AqV5Qhp_V3mEo?zf_rszfm=1' width="100%" height="450px" />

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Fourthsec