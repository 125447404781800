import React, { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";

const Blogsec = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-posts`
    );
    const result = await response.json();
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortedData = [...data].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const groupedData = [];
  for (let i = 0; i < sortedData.length; i += 3) {
    groupedData.push(sortedData.slice(i, i + 3));
  }

  return (
    <section className="blog-sec" style={{ paddingTop: 0 }}>
      <Container>
        <Row>
          <Col md={12} style={{ paddingBottom: "10px" }}>
            <h1>Blog</h1>
          </Col>
          <Col md={12}>
            <Carousel
              nextIcon={
                <div className="carousel-control-next"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    position: "absolute",
                    top: "30%",
                    transform: "translateY(-50%)",
                    right: "-50px",
                    zIndex: 1000,
                  }}
                >
                  <span
                    className="carousel-control-next-icon"
                    style={{
                      backgroundImage: "none",
                      border: "solid white",
                      borderWidth: "0 3px 3px 0",
                      height: "14px",
                      width: "14px",
                      display: "inline-block",
                      transform: "rotate(-45deg)",
                    }}
                    aria-hidden="true"
                  />
                </div>
              }
              prevIcon={
                <div className="carousel-control-prev"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    position: "absolute",
                    top: "30%",
                    transform: "translateY(-50%)",
                    left: "-50px",
                    zIndex: 1000,
                  }}
                >
                  <span
                    className="carousel-control-prev-icon"
                    style={{
                      backgroundImage: "none",
                      border: "solid white",
                      borderWidth: "0 3px 3px 0",
                      height: "14px",
                      width: "14px",
                      display: "inline-block",
                      transform: "rotate(135deg)",
                    }}
                    aria-hidden="true"
                  />
                </div>
              }
            >

              {groupedData.map((group, index) => (
                <Carousel.Item key={index}>
                  <Row>
                    {group.map((row) => (
                      <Col md={4} key={row.slug}>
                        <Link to={"/blog/" + row.slug} className="blog-link">
                          <img
                            className="d-block w-100 carousel-image"
                            src={
                              "https://storage.googleapis.com/fintechlegal/posts/" +
                              row.document
                            }
                            alt={row.posttitle}
                          />
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              color: "white",
                              marginTop: "20px",
                              marginBottom: "20px",
                              padding: "20px",
                              minHeight: "120px",
                            }}
                          >
                            <h3 style={{ fontSize: "20px" }}>{row.posttitle}</h3>
                          </div>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Link to="/blog" style={{ margin: "auto" }}>
            <Button
              className="blue-btn home-contact-btn btn btn-outline-light my-5"
              variant="outline-light"
              style={{ float: "left" }}
            >
              READ MORE BLOGS
            </Button>
          </Link>
        </Row>
      </Container>
    </section>
  );
};

export default Blogsec;
