import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Newsleter from "../../assets/newsletter-signup-bg.webp";
import "../About/About.css";
const Thirdsec = () => {
  const [packagetitle, setHeading] = useState("");
  const [packagedesc, setDesc] = useState("");
  const [packageexcerpt, setPackageexcerpt] = useState("");
  const [packagesubdescone, setPackagesubdescone] = useState("");
  const [packagedesctitle, setPackagedesctitle] = useState("");
  const [packagesubdesctwo, setPackagesubdesctwo] = useState("");
  const [packagesubdescthree, setPackagesubdescthree] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [buttontext, setButtontext] = useState("");
  const [buttonlink, setButtonlink] = useState("");
  const [fileName, setFileName] = useState("");
  const params = useParams();

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-package/${params.slug}`
    );
    result = await result.json();
    setHeading(result.packagetitle);
    setDesc(result.packagedesc);
    setPackageexcerpt(result.packageexcerpt);
    setPackagesubdescone(result.packagesubdescone);
    setPackagedesctitle(result.packagedesctitle);
    setPackagesubdesctwo(result.packagesubdesctwo);
    setPackagesubdescthree(result.packagesubdescthree);
    setButtontext(result.buttontext);
    setButtonlink(result.buttonlink);
    setFileName(result.document);
    setMetatitle(result.metatitle);
    setMetadescription(result.metadescription);
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  const styles = {
    width: "100%",
  };
  return (
    <section className="refunds-service-sec-3">
      <Container>
        <Row>
          <p className="py-5 text-center services-detailed-desc">
            {packagesubdescone}
          </p>
          <Col md={6} className="">
            <>
              <h1>{packagedesctitle}</h1>
              <p className="">{ReactHtmlParser(packagedesc)}</p>
              <Link className="nounderline" to={buttonlink}>
                <Button
                  variant="outline-light my-5"
                  className="blue-btn dynamic-detailed-btn"
                >
                  {buttontext}
                </Button>
              </Link>
            </>
          </Col>
          <Col md={6} className="py-5">
            {fileName && (
              <img
                className="about-intro-img"
                src={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
                style={styles}
              />
            )}
          </Col>
        </Row>
      </Container>
      <p className="py-3 text-center services-detailed-desc">
        {packagesubdesctwo}
      </p>
      <img style={styles} src={Newsleter} />
      <p className="py-3 text-center services-detailed-desc">
        {packagesubdescthree}
      </p>
      <Link to={"/contact"} className="nounderline">
        <Button
          variant="outline-light my-5"
          className="blue-btn res-detailed-btn"
        >
          TALK WITH FINTECH LAW
        </Button>
      </Link>
    </section>
  );
};

export default Thirdsec;
