import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const Secondsec = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-packages`);
        const result = await response.json();
        setData(result);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <section className='service-second-sec'>
            <Container>
                {data.map((row, index) => (
                    <Row>
                        {index % 2 === 0 ? (
                            <>
                                <Row className='py-3'>
                                    <Col className='service-img-col-second-sec' md={4}>
                                        <div className='img-box-services'>
                                            <h2>{row.packagetitle}</h2>
                                        </div>
                                    </Col>
                                    <Col md={8} className="pl-5 ">
                                        <h1>{row.packagetitle}</h1>
                                        <p>{row.packageexcerpt}</p>
                                        {/* <p>{ReactHtmlParser(row.packagedesc)}</p> */}
                                        <Link to={"/services/" + row.slug} className="blog-link">
                                            <Button>SERVICE DETAILS</Button></Link>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row className='py-5'>

                                    <Col md={8}>
                                        <h1>{row.packagetitle}</h1>
                                        <p>{row.packageexcerpt}</p>
                                        <Link to={"/services/" + row.slug} className="blog-link">
                                            <Button>SERVICE DETAILS</Button>
                                        </Link>
                                    </Col>
                                    <Col className='service-img-col-second-sec' md={4}>
                                        <div className='img-box-services'>
                                            <h2>{row.packagetitle}</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Row>
                ))}
                <Link className='nounderline mx-auto d-table' to={'/contact'}>
                    <Button>CONTACT OUR TEAM TODAY!</Button>
                </Link>
            </Container>
        </section>
    )
}

export default Secondsec
