import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import './ReFunds.css'

const Intro = () => {
  const [packagetitle, setHeading] = useState("");
  const [packagedesc, setDesc] = useState("");
  const [packageexcerpt, setPackageexcerpt] = useState("");
  const [packagesubdescone, setPackagesubdescone] = useState("");
  const [packagedesctitle, setPackagedesctitle] = useState("");
  const [packagesubdesctwo, setPackagesubdesctwo] = useState("");
  const [packagesubdescthree, setPackagesubdescthree] = useState("");
  const [metatitle, setMetatitle] = useState('');
  const [metadescription, setMetadescription] = useState('');
  const [fileName, setFileName] = useState("");
  const params = useParams();

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-package/${params.slug}`);
    result = await result.json();
    setHeading(result.packagetitle);
    setDesc(result.packagedesc);
    setPackageexcerpt(result.packageexcerpt);
    setPackagesubdescone(result.packagesubdescone);
    setPackagedesctitle(result.setPackagedesctitle);
    setPackagesubdesctwo(result.packagesubdesctwo);
    setPackagesubdescthree(result.packagesubdescthree);

    setFileName(result.document)
    setMetatitle(result.metatitle);
    setMetadescription(result.metadescription);
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  return (
    <section className="blogintrosec">
      <Container >
        <Row className='py-5'>
          <Col>

            <>
              <h2 className='inner-service-head'>{packagetitle}</h2>
              <h1 className='inner-service-subhead'>{packageexcerpt}</h1>

            </>

          </Col>


        </Row>

      </Container>
    </section>


  )
}

export default Intro