import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const Secondsec = () => {
  const [data, setData] = useState([]);
  const [partner, setPartner] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-team-with-order`
    );
    const result = await response.json();
    if (Array.isArray(result)) setData(result);
  };

  const fetchPartnerPage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-aboutpage`
    );
    const result = await response.json();
    if (Array.isArray(result)) setPartner(result);
  };

  useEffect(() => {
    fetchData();
    fetchPartnerPage();
  }, []);

  const styles = {
    width: "320px",
    height: "320px",
  };

  // Filter team members where displayOnTeamPage is 'yes'
  const filteredData = data.filter((row) => {
    return row.displayOnTeamPage === "yes";
  });

  return (
    <section className="meet-team-sec">
      <Container>
        {partner.map((row, index) => (
          <p className="text-center text-white">{ReactHtmlParser(row.desc)}</p>
        ))}
        <h1 className=" text-white">Get To Know Our Team</h1>
        <p className="text-center text-white">
          Here at FinTech Law, our people make all the difference.
        </p>

        <Row>
          {filteredData.map((row, index) => (
            <Col className="py-3" md={4}>
              <div class="flip-cardt">
                <div class="flip-card-innert">
                  <div class="flip-card-frontt">
                    <img
                      src={
                        "https://storage.googleapis.com/fintechlegal/posts/" +
                        row.document
                      }
                      style={styles}
                    />
                  </div>
                  <div class="flip-card-backt">
                    <div className="flip-card-backt-bg">
                      <h1>{row.membername}</h1>
                      <p className="flipcardt-position">{row.memberposition}</p>
                      <p className="flipcardt-desc">{row.memberdesc}</p>
                      <div className="icons-container">
                        {row.memberlinkden && (
                          <Link to={row.memberlinkden}>
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              size="2x"
                              className="px-2"
                            />
                          </Link>
                        )}
                        {row.membertwitter && (
                          <Link to={row.membertwitter}>
                            <FontAwesomeIcon
                              icon={faTwitter}
                              size="2x"
                              className="px-2"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Link className="nounderline" to={"/contact"}>
          <Button>CONTACT FINTECH LAW TODAY!</Button>
        </Link>
      </Container>
    </section>
  );
};

export default Secondsec;
