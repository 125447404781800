import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Secondsec.css";
import Intro from "./Intro";
import ThirdSec from "./ThirdSec";

const Secondsec = () => {
  const iconsRef = useRef([]);
  const navigate = useNavigate();
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconLeave = () => {
    setHoveredIcon(null);
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    iconsRef.current.forEach((icon) => {
      observer.observe(icon);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <section className="last-img">
        <div className="intro-ai-image">
          <div className="overlay">
            <div className="buttons-row">
              <Link
                className="nounderline"
                to="https://chat.openai.com/g/g-pacPkgaTy-fintech-law-assistant"
              >
                <Button className="mb-2">TRY IT NOW</Button>
              </Link>

              <Button
                onClick={() =>
                  window.document
                    .getElementById("subscribe-form")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                SIGN UP FOR UPDATES
              </Button>
            </div>
          </div>
          <img
            src="https://storage.googleapis.com/fintechlegal/posts/fintech-ai-cover.jpg"
            alt="AI image"
            className="slide-image"
          />
        </div>
        <div className="secondsec-text">
          <Container>
            <Row className="py-5">
              <Col>
                <>
                  <h1 className="about-head-top">
                    {"Navigate Federal Securities Law with Ease"}
                  </h1>
                  <h2 className="py-3 about-p-top">
                    {
                      "Powered by ChatGPT, the FinTech Law Assistant is your 24/7 guide through the complexities of securities regulations"
                    }
                  </h2>
                </>
              </Col>
            </Row>
            <Row className="py-5 row-icons">
              <Col className="d-flex flex-column justify-content-center">
                <div
                  onMouseEnter={() => handleIconHover("response")}
                  onMouseLeave={handleIconLeave}
                >
                  <img
                    ref={(ref) => iconsRef.current.push(ref)}
                    src="https://storage.googleapis.com/fintechlegal/posts/response-64.png"
                    alt=""
                  />
                  <h5>Instant Answers</h5>
                  <h6 className="icon-text pt-4 pr-2 pl-2">
                    Get immediate responses to your inquiries on securities law.
                  </h6>
                </div>
              </Col>
              <Col className="d-flex flex-column justify-content-center">
                <div
                  onMouseEnter={() => handleIconHover("check")}
                  onMouseLeave={handleIconLeave}
                >
                  <img
                    ref={(ref) => iconsRef.current.push(ref)}
                    src="https://storage.googleapis.com/fintechlegal/posts/check-mark-7-64.png"
                    alt=""
                  />
                  <h5>Comprehensive Coverage</h5>
                  <h6 className="icon-text pt-4 pr-2 pl-2">
                    From rules to forms, navigate the vast landscape of federal
                    securities regulations.
                  </h6>
                </div>
              </Col>
              <Col className="d-flex flex-column justify-content-center">
                <div
                  onMouseEnter={() => handleIconHover("time")}
                  onMouseLeave={handleIconLeave}
                >
                  <img
                    ref={(ref) => iconsRef.current.push(ref)}
                    src="https://storage.googleapis.com/fintechlegal/posts/time-64.png"
                    alt=""
                  />
                  <h5>Access Anytime</h5>
                  <h6 className="icon-text pt-4 pr-2 pl-2">
                    Whether you're at the office or on the move, the FinTech Law
                    Assistant is ready to assist.
                  </h6>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Intro />
      <ThirdSec />
    </>
  );
};

export default Secondsec;
